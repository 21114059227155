import React from "react"
import PageLayout from "../components/page-layout"

export default function Home() {
    return (
        <PageLayout title="会社概要">
            <table className="company">
                <tr>
                    <th>商　　　　　号</th><td>株式会社S.MATSUNAGA</td>
                </tr>
                <tr>
                    <th>設　　　　　立</th><td>平成２８年４月８日</td>
                </tr>
                <tr>
                    <th>代　　表　　者</th><td>松永　修志</td>
                </tr>
                <tr>
                    <th>本　　　　　社</th><td>〒104-0061<br />東京都中央区銀座２丁目８−１７<br />ハビウル銀座Ⅱ　４階</td>
                </tr>
                <tr>
                    <th>事　業　内　容</th><td>美容室の経営並びに運営<br />バレエ教室の経営並びに運営</td>
                </tr>
            </table>
        </PageLayout >
    )
}